<template>
  <main>
    <transition appear name="fade">
      <div v-if="space" class="detail-view">
        <div class="float-end">
          <router-link :to="{ name: 'AvailableSpace' }"
            ><h4>&times;</h4></router-link
          >
        </div>
        <div class="form-outline mb-4">
          <label>Name</label>
          <p>{{ space.name }}</p>
        </div>
        <div
          class="form-outline mb-4 row"
          id="gallery"
          data-bs-toggle="modal"
          data-bs-target="#imageModal"
        >
          <div class="gallery-item col-12 col-sm-6 col-lg-3">
            <img
              class="w-100"
              :src="space.floorPlanURLMedium"
              data-bs-target="#carouselSpace"
              data-bs-slide-to="0"
            />
          </div>
          <div class="gallery-item col-12 col-sm-6 col-lg-3">
            <img
              class="w-100"
              :src="space.image1URLMedium"
              data-bs-target="#carouselSpace"
              data-bs-slide-to="1"
            />
          </div>
          <div class="gallery-item col-12 col-sm-6 col-lg-3">
            <img
              class="w-100"
              :src="space.image2URLMedium"
              data-bs-target="#carouselSpace"
              data-bs-slide-to="2"
            />
          </div>
          <div class="gallery-item col-12 col-sm-6 col-lg-3">
            <img
              class="w-100"
              :src="space.image3URLMedium"
              data-bs-target="#carouselSpace"
              data-bs-slide-to="3"
            />
          </div>
        </div>
        <div class="form-outline mb-4 row">
          <div class="form-outline col-6 col-lg-3">
            <label>Suite Number</label>
            <p>{{ space.suiteNumber }}</p>
          </div>
          <div class="form-outline col-6 col-lg-3">
            <label>Potential Rent</label>
            <p>{{ space.potentialRent }}</p>
          </div>
          <div class="form-outline col-6 col-lg-3">
            <label>Availability</label>
            <p>{{ space.availability }}</p>
          </div>
          <div class="form-outline col-6 col-lg-3">
            <label>Usable Square Feet</label>
            <p>{{ space.sizeUsable }}</p>
          </div>
        </div>
        <div class="form-outline">
          <label>Description</label>
          <p>{{ space.description }}</p>
        </div>
        <ModalImage
          :image0="space.floorPlanURL"
          :image1="space.image1URL"
          :image2="space.image2URL"
          :image3="space.image3URL"
        />
      </div>
    </transition>
  </main>
</template>

<script>
import getDocument from "@/composables/getDocument";
import ModalImage from "@/components/modals/ModalImage.vue";

export default {
  props: ["spaceId"],
  components: { ModalImage },
  setup(props) {
    const { document: space } = getDocument("spaces", props.spaceId);
    return { space };
  }
};
</script>

<style>
.form-outline label {
  font-weight: bold;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: all 2s ease;
}
</style>