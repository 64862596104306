<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="imageModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-end">
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Carousel markup: https://getbootstrap.com/docs/4.4/components/carousel/ -->
          <div
            id="carouselSpace"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="d-block w-100" :src="image0" />
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" :src="image1" />
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" :src="image2" />
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" :src="image3" />
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselSpace"
              role="button"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselSpace"
              role="button"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </a>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["image0", "image1", "image2", "image3"]
};
</script>

<style scoped>
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}

.close {
  background: none;
  border-radius: 100px 100px 0 0;
  padding: 5px 10px;
  opacity: 1;
  position: relative;
  top: 3px;
  border: solid 2px white;

  @media (min-width: $bootstrap-lg) {
    top: 15px;
  }
}

.close:hover {
  transform: scale(1.5);
  transition: all ease 0.2s;
}
</style>